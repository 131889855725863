import React from 'react'
import TabsContainer from '../components/ProfileTabs/TabsContainer'
import Layoutes from '../layouts/Layouts'

const SettingsPage = () => {
  return (
    <Layoutes title={"Settings"}>
        <TabsContainer></TabsContainer>
    </Layoutes>
  )
}

export default SettingsPage