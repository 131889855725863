import { faBars } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faGear, faRightToBracket } from '@fortawesome/free-solid-svg-icons';
import { useContext } from 'react';
import { AuthContext } from '../hooks/contexts/AuthContext';

const icons = [
    <FontAwesomeIcon icon={faUser} />,
    <FontAwesomeIcon icon={faGear} />,
    <FontAwesomeIcon icon={faRightToBracket} />
]

const Navbar = ({handleToggleSidebar}) => {
    const { user } = useContext(AuthContext);

    const [toggle, setToggle] = useState(false)

    const handleToggle = () => {
        setToggle(!toggle);
    }

    return (
        <nav className="navbar">
            <div className="nav-left-list">
                <button 
                    type="button" 
                    className="nav-toggle-buton"
                    onClick={handleToggleSidebar}
                >
                    <FontAwesomeIcon icon={faBars} />
                </button>
                <input
                    className="nav-search-box"
                    type="search" 
                    placeholder='Search...' 
                />
            </div>
            <div className="nav-left-list">
                <div className="drop-down" 
                    // onClick={handleToggle}
                >
                <span>
                    <span>{user.email}</span>
                </span>
                {/* <div className={`drop-down-list  ${toggle ? "active" : ""}`}>
                {
                DropDownList.map(({item, url}, index) => (
                    <div className={`drop-down-item`} key={index}>
                        {icons[index]}
                        <Link to={url} >{item}</Link>
                    </div>
                ))
                }
                </div> */}
            </div>
            </div>
        </nav>
    )
}

export default Navbar;