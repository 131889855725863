import React, { useContext } from 'react';
import SidebarList from '../data/Sidebar.json';
import SidebarListItem from './SidebarListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faBoxes, faChartSimple, faHome, faListDots, faMoneyCheckDollar, faRightToBracket, faSignOut, faTimes, faUsers } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../hooks/contexts/AuthContext';

const icons = [
    <FontAwesomeIcon icon={faHome} />,
    <FontAwesomeIcon icon={faListDots} />,
    <FontAwesomeIcon icon={faBook} />,
    <FontAwesomeIcon icon={faChartSimple} />,
    <FontAwesomeIcon icon={faBoxes} />,
    <FontAwesomeIcon icon={faUsers} />,
    <FontAwesomeIcon icon={faMoneyCheckDollar} />,
    <FontAwesomeIcon icon={faRightToBracket} />
];

const Sidebar = ({activeSidebar, handleToggleSidebar}) => {

    const { handleLogout } = useContext(AuthContext);

    return (
        <aside className={`sidebar ${activeSidebar ? "active" : ""}`}>
            <div className="sidebar-header">
                <h3>Hudhude Dashboard</h3>
                <button 
                    type="button" 
                    className="button-close"
                    onClick={handleToggleSidebar}
                >
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </div>
            <div className="sidebar-body">
                <div className="sidebar-list">
                {
                SidebarList.map(({item, url}, index) => (
                    <SidebarListItem 
                        key={index} 
                        title={item} 
                        url={url} 
                        icon={icons[index]} 
                    />
                ))
                }
                <button 
                    type='button' 
                    className='sidebar-list-item'
                    onClick={handleLogout}
                >
                    <FontAwesomeIcon icon={faSignOut} />
                    <span>Logout</span>
                </button>
                </div>
            </div>
        </aside>
    )
}

export default Sidebar;