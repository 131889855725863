import React, {useState} from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";

const Layoutes = ({ children, title }) => {
    const [activeSidebar, setActiveSidebar] = useState(true);

    const handleToggleSidebar = () => {
        setActiveSidebar(!activeSidebar);
    }

    return (
        <div className="container">
            <Sidebar
                activeSidebar={activeSidebar}
                handleToggleSidebar={handleToggleSidebar}
            />
            <main className="main">
                <Navbar handleToggleSidebar={handleToggleSidebar} />
                <div className="modal-container">
                    <div className="page">
                        <h1 className="page-title">{title}</h1>
                        {children}
                    </div>

                </div>
            </main>
            {/* <PrimaryModal /> */}
        </div>
    )
}

export default Layoutes;