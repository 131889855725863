import React, { useState } from 'react'

const ChangePasswordForm = ({adminOldPassword, adminNewPassword, adminConfirmPassword}) => {
  const [oldPassword, setOldPassword] = useState(adminOldPassword);
  const [newPassword, setNewPassword] = useState(adminNewPassword);
  const [confirmPassword, setConfirmPassword] = useState(adminConfirmPassword);

  return (
    <div className="admin-form">
      <div className="form-group">
        <label htmlFor='oldPassword'>Old password</label>
        <input
          id='oldPassword'
          type="password"
          name='oldPassword'
          className="form-input-text"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label htmlFor='newPassword'>New password</label>
        <input
          id='newPassword'
          type="password"
          name='newPassword'
          className="form-input-text"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label htmlFor='confirmPassword'>Old password</label>
        <input
          id='confirmPassword'
          type="password"
          name='confirmPassword'
          className="form-input-text"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </div>
      <div className="form-button-group">
        <button type='submit' className="admin-form-primary-button">Update</button>
      </div>
    </div>
  )
}

export default ChangePasswordForm