import React from 'react'
import Layoutes from '../../layouts/Layouts'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash, faSpinner } from '@fortawesome/free-solid-svg-icons'
import PrimaryTable from '../../components/Tables/PrimaryTable';
import { useEffect } from 'react'
import axios from 'axios'
import { useState } from 'react'
import ReactPaginate from 'react-paginate';
import Variables from '../../config/global-variables';
import { useContext } from 'react';
import { AuthContext } from '../../hooks/contexts/AuthContext'
import CheckProduct from './CheckProduct'

const ProductsPage = () => {
  const user = useContext(AuthContext);

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchString, setSearchString] = useState("");
  const [language, setLanguage] = useState("en");
  const [actSearchString, setActSearchString] = useState("");
  const [page, setPage] = useState(3);
  const [productsCount, setProductsCount] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setProducts([]);
        setLoading(true)
        const result = await axios.get(`${Variables.domainName}/api/products?search=${actSearchString}&page=${page}&lang=${language}&dd=${Math.random() * 10}`, {
          headers : {
            "x-auth-token" : user.token
          }
        })
        const count = await axios.get(`${Variables.domainName}/api/reports/products`, {
          headers : {
            "x-auth-token" : user.token
          }
        });
        if (result) {
          setProducts(result.data.data);
          setLoading(false);
        }
        setProductsCount(count.data.data[0].total)

      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
    
  }, [actSearchString, page, language, user.token]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setActSearchString(searchString);
  }

  const handleDeletedProduct = async (enId, arId) => {
    const result = window.confirm(`
      You are going to deleted all Virtual Product data including audio, vedio and pdf files
      Are you sure?
    `)
    if (result) {
      try {
        const response = await axios.delete(`${Variables.domainName}/api/eproducts/${arId}?translatedProductId=${enId}`, {
          headers : {
            "Content-Type" : "application/json",
            "x-auth-token" : user.token,
          }
        })
        if (response.data.success) {
          alert("Product has deleted successfully");
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        alert("Something went wrong try again later...")
      }
    }
  }

  return (
    <Layoutes title={"Products"}>
      <div className="wrapper">
        <form onSubmit={handleFormSubmit} >
        <input 
          type="search" 
          value={searchString} 
          className="search-box"
          placeholder='Search in products...'
          onChange={(e) => setSearchString(e.target.value)} 
        />
        <select className="select-box" value={language} onChange={(e) => setLanguage(e.target.value)}>
          <option value={"en"}>English</option>
          <option value={"ar"}>Arabic</option>
        </select>
        </form>
        {
        loading ?
        <div className="loading-container">
          <FontAwesomeIcon icon={faSpinner} className="loading-icon" />
        </div> :
        <PrimaryTable headers={["id", "image", "name", "Audio", "Pdf", "Video", "Actions"]}>
        {
          products.map((element, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                <img src={element.images[0].src} width="50px" alt={element.images[0].alt} />
              </td>
              <td>{element.name}</td>
              <td>
                <CheckProduct data={element.meta_data} validation={"hudhud_eproduct_audio"} />
                <CheckProduct data={element.meta_data} validation={"hudhud_eproduct_sample_audio"} />
              </td>
              <td>
                <CheckProduct data={element.meta_data} validation="hudhud_eproduct_pdf" />
                <CheckProduct data={element.meta_data} validation="hudhud_eproduct_sample_pdf" />
              </td>
              <td>
                <CheckProduct data={element.meta_data} validation="hudhud_eproduct_video" />
                <CheckProduct data={element.meta_data} validation="hudhud_eproduct_sample_video" />
              </td>
              <td>
                  <Link 
                    to={{
                      pathname : `/products/${element.translations.ar}`
                    }} 
                    state={{ 
                      lang : element.lang,
                      enId : element.translations.en,
                      arId : element.translations.ar,
                    }}
                    className="button primary-button" 
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </Link>
                  <button 
                    type="button" 
                    className="button danger-button"
                    onClick={() => handleDeletedProduct(element.translations.en, element.translations.ar)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </td>
            </tr>
          ))
        }
        </PrimaryTable>
        }
        <div className="pagination-container">
          <ReactPaginate 
            // className="pagination"
            // breakLabel="..."
            containerClassName='pagination'
            pageLinkClassName='pagination-button'
            previousClassName='pagination-button'
            nextClassName='pagination-button'
            nextLabel=">"
            previousLabel="<"
            onPageChange={(e) => setPage(e.selected + 1)}
            activeLinkClassName="pagination-button active"
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            pageCount={Math.ceil(productsCount / 20)}
            renderOnZeroPageCount={null}
            pageClassName="pagination-list"
          />
        </div>

      </div>
    </Layoutes>
  )
}

export default ProductsPage