import React from 'react';

const PrimaryFormInput = ({label, name, type, value, handleChange}) => {
    return (
        <div className="primary-form-input-group">
            <label htmlFor={name}>{label}</label>
            <input 
                type={type}
                name={name}
                value={value}
                onChange={(e) => handleChange(e)}
                className="primary-form-input"
            />
        </div>
    )
}

export default PrimaryFormInput;