import React, { useState } from 'react';
import PrimaryForm from '../../components/Formes/PrimaryForm';
import PrimaryFormColTwo from '../../components/Formes/PrimaryFormColTwo';
import PrimaryFormInput from '../../components/Formes/PrimaryFormInput';
import PrimaryFormRow from '../../components/Formes/PrimaryFormRow';
import Layoutes from '../../layouts/Layouts';

const AddCategoryPage = () => {
    const [values, setValues] = useState({
        name : "",
        url : "",
    })

    const handleInputChange = (e) => {
        switch (e.target.name) {
            case "name" : setValues({...values, name : e.target.value}); break;
            case "url" : setValues({...values, url : e.target.vlaue}); break;
            default : setValues(values);
        }
    }

    return (
        <Layoutes title={"Add Category"}>
            <PrimaryForm>
                <PrimaryFormRow>
                    <PrimaryFormColTwo>
                    <PrimaryFormInput
                        label={"Name"}
                        type={"text"}
                        name={"name"}
                        value={values.name}
                        handleChange={handleInputChange}
                    />
                    </PrimaryFormColTwo>
                </PrimaryFormRow>
            </PrimaryForm>
        </Layoutes>
    )
}

export default AddCategoryPage;