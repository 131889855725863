import React from 'react'
import Layoutes from '../../layouts/Layouts';
import { useFormik } from 'formik';
import PlanSchema from '../../schemas/plan.schema';
import { useParams } from 'react-router-dom';

const PlaneProfile = () => {
    const { id } = useParams()

    console.log(id);

    const formik = useFormik({
        initialValues : {
            name : "",
            price : "",
            description : "",
        },
        validationSchema : PlanSchema,
        onSubmit : (values) => {
            console.log(values);
        }
    })

  return (
    <Layoutes title={"Edit Plan"}>
        <div className="plan-form">
            <form onSubmit={(e) => { e.preventDefault(); formik.handleSubmit(); }}>
                <div className="form-group">
                    <label htmlFor='name'>Name</label>
                    <input 
                        type="text" 
                        name="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        className="form-input-text"
                    />
                    <span className="text-error">{formik.errors.name}</span>
                </div>
                <div className="form-group">
                    <label htmlFor='price'>Price</label>
                    <input 
                        type={"number"}
                        name="price"
                        value={formik.values.price}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-input-text"
                    />
                    <span className="text-error">{formik.errors.price}</span>
                </div>
                <div className="form-group">
                    <label htmlFor='description'>description</label>
                    <textarea
                        name='description'
                        // value={values.description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-input-text"
                        value={formik.values.description}
                    >
                    </textarea>
                    <span className="text-error">{formik.errors.description}</span>
                </div>
                <div className="form-button-group">
                    <button type='submit' className="form-submit-button">Update</button>
                </div>
            </form>
        </div>
    </Layoutes>
  )
}

export default PlaneProfile