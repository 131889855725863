import React from 'react';

const TabsContainer = ({children, titles, activeTab = 1, setActiveTab}) => {

    return (
        <div div className="tabs-container">
            <div className="tabs-header">
            {
            titles.map((element, index) => (
                <div 
                    key={index}
                    className={`tabs-title ${activeTab === index + 1 ? "active" : ""}`}
                    onClick={() => setActiveTab(index + 1)}
                >
                    {element}
                </div>
            ))
            }
            </div>
            {children}
        </div>
    )
}

export default TabsContainer;