import React from 'react'
import { Formik } from 'formik';
import LoginSchema from '../../schemas/login.schema';
import axios from 'axios';
import Variables from '../../config/global-variables';
import { useState } from 'react';
import Message from '../Messages/Message';

const LoginForm = ({title = "Form title"}) => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  
  const handleGoogleLogin = () => {
    window.open("http://localhost:5000/auth/admin/login", "_self");
  }

  const handleFormSubmit = async (data) => {
    // console.log(data);
    try {
      const result = await axios.post(`${Variables.domainName}/api/admins/auth`, {
        email : data.email,
        password : data.password,
      }, {
        headers : {
          contentType : "application/json"
        }
      })
      console.log(result);
      if (result.data.success) {
        if (result.data.data.token) {
          setError("");
          setSuccess(true);
          localStorage.setItem("token", result.data.data.token);
          window.location.reload()
        } else {
          setSuccess(false);
          setError("Invalid Token")
        }
      } else {
        setSuccess(false);
        setError(result.data.message);
      }
      
    } catch (error) {
      console.log(error);
    }
    // try {
    //   const result = await fetch("http://82.180.154.136:3002/api/admins/auth", {
    //     method : "POST",
    //     body : {
    //       email : data.email,
    //       password : data.password, 
    //     }
    //   })
    //   console.log(result);
    // } catch (error) {
    //   console.log(error);
    // }
  }

  return (
    <div className="login-form-container">
      <Formik
        initialValues={{ email : '', password : "",}}
        validationSchema={LoginSchema}
        onSubmit={(values) => handleFormSubmit(values)}
      >
      {({values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting}) => (
        <form onSubmit={handleSubmit} className="login-form">
          <div className="form-header">
            <h1 className="form-title">{title}</h1>
          </div>
          {error ?
          <Message 
            type={"error"}
            text={error}
          /> :
          null
          }
          {
          success ?
          <Message 
            type={"success"}
            text={"You are logged in successfully!"}
          />
          : null
          }
          <div className="login-form-group">
            <label htmlFor='email'>Email *</label>
            <input
              type="text"
              name='email'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              className="login-input"
            />
            <span className="text-error">{errors.email && touched.email && errors.email}</span>
          </div>
          <div className="login-form-group">
            <label htmlFor='email'>password *</label>
            <input 
              type="password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              className="login-input"
            />
            <span className="text-error">{errors.password && touched.password && errors.password}</span>
          </div> 
          <button type="submit" disabled={isSubmitting} className="login-button">
            Submit
          </button>
        </form>
      )} 
      </Formik>
    </div>
  )
}

export default LoginForm