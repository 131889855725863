import React, { useState } from 'react'
import Layoutes from '../layouts/Layouts'
import { storage } from '../config/firebase.config';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { generateRandomString } from '../utilities/random-string-genreator';

const FileUploader = () => {
    const [file, setFile] = useState();
    const [percent, setPercent] = useState(0);
    const [fileUrl, setFileUrl] = useState("");

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    }

    const handleFileUpload = () => {
        const path = `/files/${generateRandomString(16)}.${file.type.split('/')[1]}`;
        console.log(path);

        const storageRef = ref(storage, path);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on("state_changed", (snapshot) => {
            const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
            // update progress
            setPercent(percent);
        },
        (err) => console.log(err),
        async () => {
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            console.log(url);
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!file) {
            alert("Please choose a file first!");
            return;
        }

        handleFileUpload();
    }

  return (
    <Layoutes title={"File uploader test"}>
        <form className="admin-form" onSubmit={handleSubmit} >
            <div className="input-group">
                <label htmlFor='testFile'>Add file</label>
                <input type="file" className="admin-form-input-text" onChange={handleFileChange} />
            </div>
            <button type="submit" className="admin-form-primary-button">Upload</button>
            <p>{percent} %</p>
        </form>
    </Layoutes>
  )
}

export default FileUploader