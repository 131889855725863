import { faExclamationCircle, faExclamationTriangle, faPen, faPlus, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import React from 'react';
import { useState } from 'react';
import Layouts from '../../layouts/Layouts';
import SubjectSchema from '../../schemas/subject.schema';
import { useEffect } from 'react';
import axios from 'axios';
import { useContext } from 'react';
import { AuthContext } from '../../hooks/contexts/AuthContext';
import Variables from '../../config/global-variables';
import Message from '../../components/Messages/Message';

const SubjectsPage = () => {
    const user = useContext(AuthContext);

    const [subjects, setSubjects] = useState([]);
    const [loadingError, setLoadingError] = useState("");
    const [loading, setLoading] = useState(true);
    const [subjectId, setSubjectId] = useState("");

    const [toggleModal, setToggleModal] = useState(false);

    const [modalMode, setModalMode] = useState("addition");
    const [modalTitle, setModalTitle] = useState("Add Subject");

    const [arabicName, setArabicName] = useState("");
    const [englishName, setEnglishName] = useState("");

    const [formError, setFormError] = useState(null);
    const [formLoading, setFormLoading] = useState(false);
    const [formSuccess, setFormSuccess] = useState(null);


    const handleAddSubject = () => {
        setModalMode("addition");
        setArabicName("");
        setEnglishName("");
        setModalTitle("Add new Subject");
        setToggleModal(true);
    }

    const handleEditSubject = (id, index) => {
        setSubjectId(id);
        setArabicName(subjects[index].arabicName);
        setEnglishName(subjects[index].englishName);
        setModalMode("edition");
        setModalTitle("Edit Subject " + index);
        setToggleModal(true);
    }

    const handleDeleteSubject = async (id, index) => {
        const result = window.confirm("Are you sure ?");
        if (result) {
            setSubjectId(id);
            try {
                const response = await axios.delete(`${Variables.domainName}/api/subjects/${id}`, {
                    headers : {
                        "Content-Type" : "application/json",
                        "x-auth-token" : user.token,
                    },
                });
                if (response.data.success) {
                    setSubjects(subjects.filter((element) => element._id !== id))
                    console.log("Subject deleted successfully");
                } else {
                    setLoadingError("Something went wrong while fetching subjects try again later")
                }
            } catch (error) {
                console.log(error);
            }
            
        }
    }

    const handleCancelModal = () => {
        setToggleModal(false);
    }

    const handleSubmitAddition = async (data) => {
        if (modalMode === "addition") {
            setFormLoading(true);
            try {
                const result = await axios({
                    method : "POST",
                    url : `${Variables.domainName}/api/subjects`,
                    headers : {
                        "Content-Type" : "application/json",
                        "x-auth-token" : user.token,
                    },
                    data : {
                        arabicName : data.arabicName,
                        englishName : data.englishName,
                    }
                })
                if (result.data.success) {
                    setFormLoading(false);
                    setFormError(null);
                    setSubjects([...subjects, {
                        arabicName : result.data.data.arabicName,
                        englishName : result.data.data.englishName,
                        _id : result.data.data._id,
                    }])
                    setFormSuccess("Created Successfully!");
                    setTimeout(() => {
                        setFormSuccess(null);
                        setToggleModal(false)
                    }, 2000)
                } else {
                    setFormSuccess(false);
                    setFormError("Something went wrong try again later");
                    setFormLoading(false);
                }
            } catch (error) {
                setFormSuccess(false);
                setFormError("Something went wrong try again later");
                setFormLoading(false);
            }
        } else {
            setFormLoading(true);
            try {
                const result = await axios({
                    method : "PUT",
                    url : `${Variables.domainName}/api/subjects/${subjectId}`,
                    headers : {
                        "Content-Type" : "application/json",
                        "x-auth-token" : user.token,
                    },
                    data : {
                        arabicName : data.arabicName,
                        englishName : data.englishName,
                    }
                })
                if (result.data.success) {
                    setFormLoading(false);
                    setFormError(null);
                    setSubjects(subjects.map((element, index) => {
                        if (element._id === subjectId) {
                            return ({
                                arabicName : result.data.data.arabicName,
                                englishName : result.data.data.englishName,
                                _id : subjectId,
                            })
                        } else {
                            return (element)
                        }
                    }))
                    setFormSuccess("Subject Edited successfully");
                    setTimeout(() => {
                        setFormSuccess(null);
                        setToggleModal(false);
                    }, 2000);
                } else {
                    setFormSuccess(null);
                    setFormError("Something went wrong try again later");
                    setFormLoading(false);
                }
            } catch (error) {
                setFormSuccess(null);
                setFormError("Something went wrong try again later");
                setFormLoading(false);
            }
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios({
                    method : "GET",
                    url : "http://82.180.154.136:3002/api/subjects",
                    headers : {
                        "Content-Type" : "application/json",
                        "x-auth-token" : user.token,
                    }
                })
                if (result.data.success) {
                    setSubjects(result.data.data);
                    setLoading(false);
                } else {
                    setLoadingError("Something went wrong while fetching subjects try again later")
                    setLoading(false);
                }
            } catch (error) {
                setLoadingError("Something went wrong while fetching subjects try again later")
                setLoading(false);
            }
        }
        fetchData();
    }, [user.token])

    return (
        <Layouts title={"Subjects"} >
            <div className={`modal ${toggleModal ? "active" : ""}`}>
                <Formik
                    initialValues={{ englishName : englishName, arabicName : arabicName}}
                    validationSchema={SubjectSchema}
                    onSubmit={(values, actions) => {
                        handleSubmitAddition(values)
                        actions.setValues({ arabicName : "", englishName : "" })
                    }}
                >
                {
                props => (
                <form className="modal-form" onSubmit={props.handleSubmit} >
                    {
                    formLoading ?
                    <div className="form-loading-container">
                        <FontAwesomeIcon icon={faSpinner} className="loading-icon" />
                    </div> :
                    ""
                    }
                    <h2 className="title">{modalTitle}</h2>
                    {
                    formError ?
                    <div className="error-wrapper">
                        <Message text={formError} type={"error"} /> 
                    </div> :
                    formSuccess ?
                    <div className="error-wrapper">
                        <Message text={formSuccess} type={"success"} />
                    </div> :
                    ""
                    }
                    <div className="form-group">
                        <label htmlFor="enName">English Name :</label>
                        <input 
                            // id='enName'
                            type="text" 
                            className="form-control"
                            value={props.values.englishName}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            name="englishName"
                            placeholder={englishName}
                        />
                        {props.errors.englishName && <div className="error-text">{props.errors.englishName}</div>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="arName">Arabic Name :</label>
                        <input 
                            // id="arName"
                            type="text"
                            className="form-control"
                            value={props.values.arabicName}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            name={"arabicName"}
                            placeholder={arabicName}
                        />
                        {props.errors.arabicName && <div className="error-text">{props.errors.arabicName}</div>}
                    </div>
                    <div className="form-button-group">
                        <button 
                            type="button" 
                            className="cancel-button"
                            onClick={handleCancelModal}
                        >
                            Cancel
                        </button>
                        <button type="submit" className="submit-button">
                            Submit
                        </button>
                    </div>
                </form>
                )
                }
                </Formik>
                
            </div>

            <div className="wrapper">
                <button 
                    type="button" 
                    className="button primary-button larg-button"
                    onClick={handleAddSubject}
                >
                    <FontAwesomeIcon icon={faPlus} />
                    <span>Add new Subject</span>
                </button>
                {
                loadingError ?
                <div className="loading-container">
                    <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />
                    <h3 className="danger-text">{loadingError}</h3>
                </div> :
                loading ?
                <div className="loading-container">
                    <div className="loading-icon">
                        <FontAwesomeIcon icon={faSpinner} />
                    </div>
                </div> :
                subjects.length <= 0 ?
                <div className="loading-container">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="not-found-icon" />
                    <h3 className="primary-text">There are no subjects</h3>
                </div> :
                <table className="subjects-table">
                    <thead className="header">
                        <tr>
                            <th>English Name</th>
                            <th>Arabic name</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                    subjects.map((element, index) => (
                        <tr key={index}>
                            <td>{element.englishName}</td>
                            <td>{element.arabicName}</td>
                            <td>
                                <button 
                                    type="button" 
                                    className="button primary-button"
                                    onClick={() => handleEditSubject(element._id, index)}
                                >
                                    <FontAwesomeIcon icon={faPen} />
                                </button>
                                <button 
                                    type="button" 
                                    className="button danger-button"
                                    onClick={() => handleDeleteSubject(element._id, index)}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                            </td>
                        </tr>
                    ))
                    }
                    </tbody>
                </table>
                }
            </div>
        </Layouts>
    )
}

export default SubjectsPage;