import React from 'react';
import { NavLink } from 'react-router-dom';

const SidebarListItem = ({title, url, icon}) => {
    return (
    <NavLink 
        to={url} 
        className={({isActive}) => `sidebar-list-item ${isActive ? "active" : ""}`}
    >
        {icon}
        <span>{title}</span>
    </NavLink>
    )
}

export default SidebarListItem;