import { faCheck, faPen, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import ButtonLoading from '../../components/Loading/ButtonLoading';
import Message from '../../components/Messages/Message';
import Variables from '../../config/global-variables';
import { AuthContext } from '../../hooks/contexts/AuthContext';

const AudioForm = ({ 
    productId,
    arId,
    enId,
    audioFileURL, 
    audioFileSampleURL
}) => {
    const user = useContext(AuthContext);

    const [oldAudioFile, setOldAudioFile] = useState();
    const [oldAudioFileSample, setOldAudioFileSample] = useState();

    const [audioFile, setAudioFile] = useState();
    const [audioFileSample, setAudioFileSample] = useState();
    
    const [testAudioFileSample, setTestAudioFileSample] = useState();
    const [testAudioFile, setTestAudioFile] = useState();

    const [loadingOne, setLoadingOne] = useState(false);
    const [successOne, setSuccessOne] = useState(null);
    const [errorOne, setErrorOne] = useState(null);

    const [loadingTwo, setLoadingTwo] = useState(false);
    const [successTwo, setSuccessTwo] = useState(null);
    const [errorTwo, setErrorTwo] = useState(null);

    
    const handleAudioFileChange = (e) => {
        setAudioFile(e.target.files[0])
        setTestAudioFile(URL.createObjectURL(e.target.files[0]));
    }

    const handleAudioFileSampleChange = (e) => {
        setAudioFileSample(e.target.files[0])
        setTestAudioFileSample(URL.createObjectURL(e.target.files[0]));
    }

    const handleSubmit = async (e) => {
        setSuccessOne(null);
        setErrorOne(null);
        e.preventDefault();
        if (!audioFile) {
            alert("You have to upload something before!")
            return;
        }

        if (!productId) {
            alert("We couldn't find the product id")
            return;
        }

        setLoadingOne(true);
        const formData = new FormData();
        formData.append("productId", arId * 1);
        formData.append("translatedProductId", enId * 1);
        formData.append("audioFile", audioFile);
        try {
            const result = await axios({
                method : "post",
                url : `${Variables.domainName}/api/eproducts/upload/audioSubscriber`,
                data : formData,
                headers: { 
                    "Content-Type": "multipart/form-data",
                    "x-auth-token" : user.token,
                },
            })
            if (result.data.success) {
                setSuccessOne("File uploaded successfully");
                setErrorOne(null);
                setOldAudioFile(true);
                setLoadingOne(false);
                setTimeout(() => {
                    setSuccessOne(null);
                }, 3000)
                return;
            } else {
                setSuccessOne(null);
                setErrorOne(result.data.message);
                setLoadingOne(false);
                return;
            }
        } catch (error) {
            console.log(error);
            setSuccessOne(null);
            setErrorOne("Something went wrong");
            setLoadingOne(false);
            return;
        }
    }

    const handleSubmitTwo = async (e) => {
        setSuccessTwo(null);
        setErrorTwo(null);
        e.preventDefault();
        if (!audioFileSample) {
            alert("You have to upload something before!")
            return;
        }

        if (!productId) {
            alert("We couldn't find the product id")
            return;
        }

        setLoadingTwo(true);
        const formData = new FormData();
        formData.append("productId", arId);
        formData.append("translatedProductId", enId);
        formData.append("audioFile", audioFileSample);
        try {
            const result = await axios({
                method : "post",
                url : `${Variables.domainName}/api/eproducts/upload/audio`,
                data : formData,
                headers : {
                    "Content-Type": "multipart/form-data",
                    "x-auth-token" : user.token,
                }
            })
            if (result.data.success) {
                setSuccessTwo("File upladed successfully");
                setErrorTwo(null);
                setOldAudioFileSample(true);
                setLoadingTwo(false);
                setTimeout(() => {
                    setSuccessTwo(null);
                }, 3000);
                return;
            } else {
                setSuccessTwo(null);
                setErrorTwo(null);
                setLoadingTwo(false);
                return;
            }
        } catch (error) {
            console.log(error);
            setSuccessTwo(null);
            setErrorTwo("Something went wrong");
            setLoadingTwo(false);
        }
    }

    useEffect(() => {
        setOldAudioFile(audioFileURL);
        setOldAudioFileSample(audioFileSampleURL);
    }, [audioFileURL, audioFileSampleURL])

    return (
        <div>
            <form className="product-profile-form" onSubmit={handleSubmit}>
                {
                successOne ?
                <Message type="success" text={successOne} /> :
                errorOne ?
                <Message type="error" text={errorOne} /> :
                null
                }
                <div className="form-group">
                    <label htmlFor="testAudioFile">Audio file</label>
                    <div className="audio-group">
                        <div className="ball">
                            {oldAudioFile ? <FontAwesomeIcon icon={faCheck} /> : ""}
                        </div>
                        <input 
                            type="file" 
                            className="form-input-file"
                            hidden
                            id="testAudioFile"
                            onChange={handleAudioFileChange}
                            accept='.mp3'
                        />
                        <audio controls src={testAudioFile} crossOrigin="anonymous">

                        </audio>
                        <label htmlFor='testAudioFile' className="form-button form-button-primary">
                            <FontAwesomeIcon icon={faPen} />
                        </label>
                        {/* <ButtonLoading
                            type='button'
                            loading={loadingOne}
                            className="form-button form-button-danger"
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </ButtonLoading> */}
                        <ButtonLoading
                            type="submit"
                            loading={loadingOne}
                            className="form-button form-button-secondary"
                        >
                            <FontAwesomeIcon icon={faUpload} />
                        </ButtonLoading>
                    </div>
                </div>
            </form>
            <form className="product-profile-form" onSubmit={handleSubmitTwo}>
                {
                successTwo ?
                <Message type="success" text={successTwo} /> :
                errorTwo ?
                <Message type="error" text={errorTwo} /> :
                null
                }
                <div className="form-group">
                    <label htmlFor="testAudioFileSample">Audio file (Sample)</label>
                    <div className="audio-group">
                    <div className="ball">
                        {oldAudioFileSample ? <FontAwesomeIcon icon={faCheck} /> : ""}
                    </div>
                    <input 
                        type="file" 
                        className="form-input-file"
                        hidden
                        id="testAudioFileSample"
                        onChange={handleAudioFileSampleChange}
                        accept='.mp3'
                    />
                    <audio controls src={testAudioFileSample}>
                    </audio>
                    <label htmlFor='testAudioFileSample' className="form-button form-button-primary">
                        <FontAwesomeIcon icon={faPen} />
                    </label>
                    {/* <button type="submit" className="form-button form-button-secondary">Uplaod</button> */}
                    <ButtonLoading
                        type="loading"
                        className="form-button form-button-danger"
                        loading={loadingTwo}
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </ButtonLoading>
                    <ButtonLoading
                        type="submit"
                        className="form-button form-button-secondary"
                        loading={loadingTwo}
                    >
                        <FontAwesomeIcon icon={faUpload} />
                    </ButtonLoading>
                    </div>
                    {/* <ProgressBar percent={audioFileSamplePercent} /> */}
                </div>
            </form>
        </div>

    )
}

export default AudioForm;