import React from 'react';
import { useState } from 'react';
import AudioForm from './AudioForm';
import PdfForm from './PdfForm';
import VideoForm from './VideoFrom';
import ProductFormEdition from './ProductFormEdition';

const TabsForms = ({
    productId,
    arId,
    enId,
    product = {},
    lang, 
    titles
}) => {
    const [index, setIndex] = useState(1);

    return (
        <div className="tabs-forms">
            <div className="tabs-buttons">
            {
            titles.map((item, itemIndex) => (
                <button 
                    onClick={() => setIndex(itemIndex + 1)} 
                    className={`tab-button ${index === itemIndex + 1 ? "active" : ""}`}
                >{item}</button>

            ))
            }
            </div>
            <div className="tabs-container">
                <div className={`single-tab ${index === 1 ? "active" : ""}`}>
                    <ProductFormEdition
                        productId={productId} 
                        oldProduct={{
                            series : product.bookSeries,
                            awarded : product.awarded,
                            openSemsem : product.openSesame,
                            subjects : product.subjects,
                            level : product.readingLevel,
                            lang : product.RTL,
                        }}
                        arId={arId}
                        enId={enId}
                        lang={lang}
                    />
                </div>
                <div className={`single-tab ${index === 2 ? "active" : ""}`}>
                    <AudioForm 
                        audioFileSampleURL={product.sampleAudioURL} 
                        audioFileURL={product.audioURL}
                        productId={productId}
                        arId={arId}
                        enId={enId}
                    />
                </div>
                <div className={`single-tab ${index === 3 ? "active" : ""}`}>
                    <PdfForm
                        pdfFileURL={product.pdfURL}
                        pdfFileSampleURL={product.samplePDFURL}
                        arId={arId}
                        enId={enId}
                    />
                </div>
                <div className={`single-tab ${index === 4 ? "active" : ""}`}>
                    <VideoForm 
                        videoFileURL={product.videoURL}
                        videoSampleFileURL={product.sampleVideoURL}
                        arId={arId}
                        enId={enId}
                    />
                </div>
            </div>
        </div>
    )
}

export default TabsForms;