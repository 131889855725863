import React from 'react';
import Layoutes from '../../layouts/Layouts';
import Plans from '../../data/Plans.json';
import PlanCard from '../../components/Cards/PlanCard';

const PlansPage = () => {
  return (
    <Layoutes title={"Plans"}>
        <div className="plans-grid">
        {
        Plans.map((element, index) => (
            <PlanCard 
                key={index}
                id={index}
                title={element.title}
                description={element.description}
                price={element.price}
            />
        ))
        }
        </div>
    </Layoutes>
  )
}

export default PlansPage