import React from 'react'
import Layoutes from '../layouts/Layouts'
import PrimaryTable from '../components/Tables/PrimaryTable';
import CustomersSeed from '../seeder/customers.seeder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';

const CustomersPage = () => {
  return (
    <Layoutes title={"Customers"}>
        <PrimaryTable
          headers={["#ID", "Image", "Name", "Email", "Phone", "Actions"]}
        >
        {
        CustomersSeed.map((element, index) => (
          <tr key={index}>
            <td>{element.id}</td>
            <td>
              <img src={element.image} alt={element.name} width="40px" height={"40px"} />
            </td>
            <td>{element.name}</td>
            <td>{element.email}</td>
            <td>{element.phone}</td>
            <td>
              <button type="butotn" className="button primary-button">
                <FontAwesomeIcon icon={faPen} />
              </button>
              <button type="button" className="button danger-button">
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </td>
          </tr>
        ))
        }
        </PrimaryTable>
    </Layoutes>
  )
}

export default CustomersPage