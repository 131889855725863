import React from 'react';

const PrimaryFormRow = ({children}) => {
    return (
        <div className="primary-form-row">
            {children}
        </div>
    )
}

export default PrimaryFormRow;