import React, { useState } from 'react'
import Layoutes from '../layouts/Layouts'
import TabsContainer from '../components/ProfileTabs/TabsContainer';
import Tab from '../components/ProfileTabs/Tab';
import AdminForm from '../components/Formes/AdminForm';
import ChangePasswordForm from '../components/Formes/ChangePasswordForm';

const tabsTitles = ["Admin details", "Change password", "Settings"]

const ProfilePage = () => {
  const [activeTab, setActiveTab] = useState(2);

  return (
    <Layoutes title={"Your Profile"}>
      <div className="profile-row">
        {/* <div className="profile-col-2">
          <ProfileCard image={UserOneImage} name={"Dean Winchester"} />
        </div> */}
        <div className="profile-col-1">
          <TabsContainer titles={tabsTitles} activeTab={activeTab} setActiveTab={setActiveTab}>
            <Tab activeTab={activeTab} index={1}>
              <AdminForm />
            </Tab>
            <Tab activeTab={activeTab} index={2}>
              <ChangePasswordForm />
            </Tab>
            <Tab activeTab={activeTab} index={3}>Tab Three</Tab>
          </TabsContainer>
        </div>
      </div>
    </Layoutes>
  )
}

export default ProfilePage