import React from 'react'
import { Link } from 'react-router-dom'

const PlanCard = ({id, title, description, price}) => {
  return (
    <div className="plan-card">
        <h1 className="card-title">{title}</h1>
        <p className="card-price">$ {price}</p>
        <p className="card-description">{description}</p>
        <div className="card-button-group">
          <Link to={`/plans/${id}`} className="card-button-primary">Edit</Link>
        </div>
    </div>
  )
}

export default PlanCard