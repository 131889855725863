import { faker } from '@faker-js/faker';

const CustomersSeed = [];

for (var i = 0; i < 10; i++) {
    CustomersSeed.push({
        id : i,
        image : faker.image.avatar(),
        name : faker.name.fullName(),
        email : faker.internet.email(),
        phone : faker.phone.number(),
    })
}

export default CustomersSeed;