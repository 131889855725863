import { faCheck, faPen, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import ButtonLoading from '../../components/Loading/ButtonLoading';
import Message from '../../components/Messages/Message';
import Variables from '../../config/global-variables';
import { AuthContext } from '../../hooks/contexts/AuthContext';

const VideoFrom = ({arId, enId, videoSampleFileURL, videoFileURL}) => {
    const user = useContext(AuthContext);

    const [oldVideoFile, setOldVideoFile] = useState();
    const [oldVideoFileSample, setOldVideoFileSample] = useState();

    const [videoFile, setVideoFile] = useState();
    const [videoSampleFile, setVideoSampleFile] = useState();

    const [videoFileName, setVideoFileName] = useState("");
    const [videoFileSampleName, setVideoFileSampleName] = useState("");

    const [loadingOne, setLoadingOne] = useState(false);
    const [successOne, setSuccessOne] = useState(null);
    const [errorOne, setErrorOne] = useState(null);

    const [loadingTwo, setLoadingTwo] = useState(false);
    const [successTwo, setSuccessTwo] = useState(null);
    const [errorTwo, setErrorTwo] = useState(null);

    const handleChageVideoFile = (e) => {
        setVideoFile(e.target.files[0]);
        setVideoFileName(e.target.files[0].name)
    }

    const handleChangeVideoSampleFile = (e) => {
        setVideoSampleFile(e.target.files[0]);
        setVideoFileSampleName(e.target.files[0].name);
    }

    const handleSubmitOne = async (e) => {
        setErrorOne(null);
        setSuccessOne(null);
        e.preventDefault();
        if (!videoFile) {
            alert("You have to set a file first");
            return;
        }
        if (!arId){
            alert("Something wrong with product id");
            return;
        }
        
        setLoadingOne(true);
        const formData = new FormData();
        formData.append("productId", parseInt(arId));
        formData.append("translatedProductId", parseInt(enId))
        formData.append("videoFile", videoFile);

        try {
            const result = await axios.post(`${Variables.domainName}/api/eproducts/upload/videoSubscriber`,
                formData,
                {
                    headers: { 
                        "Content-Type": "multipart/form-data",
                        "x-auth-token" : user.token,
                    },
                }
            )
            if (result.data.success) {
                setSuccessOne("File uploaded successfully");
                setErrorOne(null);
                setOldVideoFile(true);
                setLoadingOne(false);
                setTimeout(() => {
                    setSuccessOne(null);
                }, 3000);
                return;
            } else {
                setSuccessOne(null);
                setErrorOne(result.data.message);
                setLoadingOne(false);
                return;
            }
        } catch (error) {
            setSuccessOne(null);
            setErrorOne("Something went wrong with the server");
            setLoadingOne(false);
            return;
        }
    }

    const handleSubmitTwo = async (e) => {
        setErrorTwo(null);
        setSuccessTwo(null);
        e.preventDefault();
        if (!videoSampleFile) {
            alert("You have to set a file first");
            return;
        }
        if (!arId) {
            alert("You have to set a file first");
            return;
        }

        setLoadingTwo(true);
        const formData = new FormData();
        formData.append("productId", parseInt(arId));
        formData.append("translatedProductId", parseInt(enId));
        formData.append("videoFile", videoSampleFile);

        try {
            const result = await axios.post(`${Variables.domainName}/api/eproducts/upload/video`, 
                formData, 
                {
                    headers : {
                        "Content-Type" : "multipart/form-data",
                        "x-auth-token" : user.token,
                    }
                }
            )
            if (result.data.success) {
                setSuccessTwo("File uploaded successfully");
                setErrorTwo(null);
                setOldVideoFileSample(true);
                setLoadingTwo(false);
                setTimeout(() => {
                    setSuccessTwo(null);
                }, 3000);
                return;
            } else {
                setSuccessTwo(null);
                setErrorTwo(result.data.message);
                setLoadingTwo(false);
                return;
            }
        } catch (error) {
            setSuccessTwo(null);
            setErrorTwo("Something went wrong");
            setLoadingTwo(false);
        }
    }

    useEffect(() => {
        setOldVideoFile(videoFileURL);
        setOldVideoFileSample(videoSampleFileURL);
    }, [videoFileURL, videoSampleFileURL])

    return (
        <div >
            <form className="product-profile-form" onSubmit={handleSubmitOne} >
                {
                successOne ?
                <Message type="success" text={successOne} /> :
                errorOne ?
                <Message type="error" text={errorOne} /> :
                null
                }
                <div className="form-group">
                    <label htmlFor='videoFile'>Video file</label>
                    <div className="pdf-group">
                        <div className="ball">
                            {oldVideoFile ? <FontAwesomeIcon icon={faCheck} /> : null}
                        </div>
                        <input
                            type="file"
                            className="form-input-file"
                            id='videoFile'
                            hidden
                            accept='.mp4'
                            onChange={handleChageVideoFile}
                        />
                        <label htmlFor='videoFile' className="pdf-name">{videoFileName}</label>
                        <label htmlFor='videoFile' className="form-button form-button-primary">
                            <FontAwesomeIcon icon={faPen} />
                        </label>
                        {/* <ButtonLoading
                            type={"button"}
                            loading={loadingOne}
                            className="form-button form-button-danger"
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </ButtonLoading> */}
                        <ButtonLoading 
                            type='submit'
                            loading={loadingOne}
                            className="form-button form-button-secondary"
                        >
                            <FontAwesomeIcon icon={faUpload} />
                        </ButtonLoading>
                    </div>
                </div>
            </form>
            <form className="product-profile-form" onSubmit={handleSubmitTwo}>
                {
                successTwo ?
                <Message type="success" text={successTwo} /> :
                errorTwo ?
                <Message type="error" text={errorTwo} /> :
                null
                }
                <div className="form-group">
                    <label htmlFor='videoSampleFile'>Video sample file</label>
                    <div className="pdf-group">
                        <div className='ball'>
                            {oldVideoFileSample ? <FontAwesomeIcon icon={faCheck} /> : ""}
                        </div>
                        <input 
                            type="file"
                            className="form-input-file"
                            id='videoSampleFile'
                            hidden
                            accept='.mp4'
                            onChange={handleChangeVideoSampleFile}
                        />
                        <label htmlFor='videoSampleFile' className="pdf-name">{videoFileSampleName}</label>
                        <label htmlFor='videoSampleFile' className="form-button form-button-primary">
                            <FontAwesomeIcon icon={faPen} />
                        </label>
                        {/* <button type="submit" className="form-button form-button-secondary">Upload</button> */}
                        <ButtonLoading
                            type={"button"}
                            loading={loadingTwo}
                            className="form-button form-button-danger"
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </ButtonLoading>
                        <ButtonLoading 
                            type='submit'
                            loading={loadingTwo}
                            className="form-button form-button-secondary"
                        >
                            <FontAwesomeIcon icon={faUpload} />
                        </ButtonLoading>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default VideoFrom;