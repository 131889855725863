import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const ButtonLoading = ({ 
    type = "button",
    className,
    loading = true,
    handleClick,
    children
}) => {
    return (
        <button
            type={type}
            className={`${className} loading-button`}
            onClick={handleClick}
        >
        {
        loading ?
        <div className='loading-loop'>
            <FontAwesomeIcon icon={faSpinner} color={"white"} />
        </div> :
        children
        }
        </button>
    )
}

export default ButtonLoading