import { faCheck, faPen, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useState, useContext, useEffect } from 'react';
import ButtonLoading from '../../components/Loading/ButtonLoading';
import Message from '../../components/Messages/Message';
import Variables from '../../config/global-variables';
import { AuthContext } from '../../hooks/contexts/AuthContext';

const PdfForm = ({ productId, arId, enId, pdfFileURL, pdfFileSampleURL }) => {
    const user = useContext(AuthContext);

    const [oldPdfFile, setOldPdfFile] = useState();
    const [oldPdfFileSample, setOldPdfFileSample] = useState();

    const [pdfFile, setPdfFile] = useState();
    const [pdfFileSample, setPdfFileSample] = useState();

    const [testPdfText, setTestPdfText] = useState();
    const [testPdfSampleText, setTestPdfSampleText] = useState();

    const [loadingOne, setLoadingOne] = useState(false);
    const [successOne, setSuccessOne] = useState(null);
    const [errorOne, setErrorOne] = useState(null);
    
    const [loadingTwo, setLoadingTwo] = useState(false);
    const [successTwo, setSuccessTwo] = useState(null);
    const [errorTwo, setErrorTwo] = useState(null);

    const handlePdfFileChange = (e) => {
        setPdfFile(e.target.files[0]);
        setTestPdfText(e.target.files[0].name);
    }
  
    const handlePdfFileSampleChange = (e) => {
        setPdfFileSample(e.target.files[0]);
        setTestPdfSampleText(e.target.files[0].name);
    }

    const handleSubmit = async (e) => {
        setErrorOne(null);
        setSuccessOne(null);
        e.preventDefault();
        if (!pdfFile) {
            alert("You have to upload a file first");
        }
        if (!arId) {
            alert("Something wrong happend with product id");
        }

        setLoadingOne(true);
        const formData = new FormData();
        formData.append("productId", arId);
        formData.append("translatedProductId", enId);
        formData.append("pdfFile", pdfFile);

        try {
            const result = await axios({
                method : "post",
                url : `${Variables.domainName}/api/eproducts/upload/pdfSubscriber`,
                data : formData,
                headers: { 
                    "Content-Type": "multipart/form-data",
                    "x-auth-token" : user.token,
                },
            })
            if (result.data.success) {
                setSuccessOne("File uploaded successfully");
                setErrorOne(null);
                setOldPdfFile(true);
                setLoadingOne(false);
                setTimeout(() => {
                    setSuccessOne(null);
                }, 3000);
                return;
            } else  {
                setSuccessOne(null);
                setErrorOne(result.data.message);
                setLoadingOne(false)
                return;
            }
        } catch (error) {
            console.log(error);
            setSuccessOne(null);
            setErrorOne("Something went wrong with the server");
            setLoadingOne(false);
            return;
        }
    }

    const handleSubmitTwo = async (e) => {
        setErrorTwo(null);
        setSuccessTwo(null)
        e.preventDefault();
        if (!pdfFileSample) {
            alert("You have to upload a file first");
            return;
        }
        if (!arId) {
            alert("Something wrong happend with product id");
            return;
        }

        setLoadingTwo(true);
        
        const formData = new FormData();
        formData.append("productId", arId);
        formData.append("translatedProductId", enId);
        formData.append("pdfFile", pdfFileSample);

        try {
            const result = await axios({
                method : "post",
                url : `${Variables.domainName}/api/eproducts/upload/pdf`,
                data : formData,
                headers: { 
                    "Content-Type": "multipart/form-data",
                    "x-auth-token" : user.token,
                },
            })
            if (result.data.success) {
                setSuccessTwo("File uploaded successfully");
                setErrorTwo(null);
                setOldPdfFileSample(true);
                setLoadingTwo(false);
                setTimeout(() => {
                    setSuccessTwo(null);
                }, 3000);
                return;
            } else {
                setSuccessTwo(null);
                setErrorTwo(result.data.message)
                setLoadingTwo(false);
                return;
            }
        } catch (error) {
            setErrorTwo("Something went wrong with the server");
            setSuccessTwo(null)
            setLoadingTwo(false);
            return;
        }
    }

    useEffect(() => {
        setOldPdfFile(pdfFileURL);
        setOldPdfFileSample(pdfFileSampleURL);
    }, [pdfFileURL, pdfFileSampleURL])

    return (
        <div>
            <form className="product-profile-form" onSubmit={handleSubmit}>
                {
                successOne ?
                <Message type="success" text={successOne} /> :
                errorOne ?
                <Message type="error" text={errorOne} /> :
                null
                }
                <div className="form-group">
                    <label htmlFor='pdfFile'>Pdf file</label>
                    <div className="pdf-group">
                    <div className="ball">
                        {oldPdfFile ? <FontAwesomeIcon icon={faCheck} /> : ""}
                    </div>
                    <input 
                        type="file"
                        className="form-input-file"
                        id='pdfFile'
                        hidden
                        accept='.pdf'
                        onChange={handlePdfFileChange}
                    />
                    <label htmlFor='pdfFile' className="pdf-name">{testPdfText}</label>
                    <label htmlFor='pdfFile' className="form-button form-button-primary">
                        <FontAwesomeIcon icon={faPen} />
                    </label>
                    {/* <button type="submit" className="form-button form-button-secondary">Upload</button> */}
                    <ButtonLoading
                        type={"button"}
                        loading={loadingOne}
                        className="form-button form-button-danger"
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </ButtonLoading>
                    <ButtonLoading 
                        type='submit'
                        loading={loadingOne}
                        className="form-button form-button-secondary"
                    >
                        <FontAwesomeIcon icon={faUpload} />
                    </ButtonLoading>
                    </div>
                </div>
            </form>
            <form className="product-profile-form" onSubmit={handleSubmitTwo}>
                {
                successTwo ?
                <Message type="success" text={successTwo} /> :
                errorTwo ?
                <Message type="error" text={errorTwo} /> :
                null
                }
              <div className="form-group">
                <label htmlFor='pdfFileSample'>Pdf file (Sample)</label>
                <div className="pdf-group">
                    <div className="ball">
                        {oldPdfFileSample ? <FontAwesomeIcon icon={faCheck} /> : ""}
                    </div>
                  <input 
                    type="file"
                    className="form-input-file"
                    id='pdfFileSample'
                    hidden
                    accept='.pdf'
                    onChange={handlePdfFileSampleChange}
                  />
                  <label htmlFor='pdfFileSample' className="pdf-name">{testPdfSampleText}</label>
                  <label htmlFor='pdfFileSample' className="form-button form-button-primary">
                    <FontAwesomeIcon icon={faPen} />
                  </label>
                  {/* <ButtonLoading
                        type={"button"}
                        loading={loadingTwo}
                        className="form-button form-button-danger"
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </ButtonLoading> */}
                  <ButtonLoading
                    type='submit'
                    loading={loadingTwo}
                    className={"form-button form-button-secondary"}
                  >
                    <FontAwesomeIcon icon={faUpload} />
                  </ButtonLoading>
                </div>
              </div>
            </form>
        </div>
    )
}

export default PdfForm;