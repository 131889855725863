import React from 'react'
import { useContext } from 'react';
import CardColFour from '../components/Cards/CardColFour';
import InfoCardGrid from '../components/Cards/InfoCardGrid';
import PrimaryInfoCard from '../components/Cards/PrimaryInfoCard';
import { AuthContext } from '../hooks/contexts/AuthContext';
import Layoutes from '../layouts/Layouts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxes, faTruck, faUsers, faWallet } from '@fortawesome/free-solid-svg-icons';

const HomePage = () => {
  const user = useContext(AuthContext);
  console.log(user);

  return (
    <Layoutes title="Home">
      <InfoCardGrid>
        <CardColFour>
          <PrimaryInfoCard 
            title={"Products"}
            subtitle={88}
            icon={<FontAwesomeIcon icon={faBoxes} />}
            url={'/products'}
            urlText={"View all products"}
          />
        </CardColFour>
        <CardColFour>
          <PrimaryInfoCard
            title={"customers"}
            type={"two"}
            subtitle={"122"}
            icon={<FontAwesomeIcon icon={faUsers} />}
            url={"/customers"}
            urlText={"View all customers"}
          />
        </CardColFour>
        <CardColFour>
          <PrimaryInfoCard 
            title={"Orders"}
            subtitle={"77"}
            type="three"
            icon={<FontAwesomeIcon icon={faTruck} />}
            url={"/categories"}
            urlText={"View all orders"}
          />
        </CardColFour>
        <CardColFour>
          <PrimaryInfoCard
            title={"Earnings"}
            subtitle={"20.625$"}
            type={"four"}
            icon={<FontAwesomeIcon icon={faWallet} />}
            url={"/home"}
            urlText={"View all"}
          />
        </CardColFour>
      </InfoCardGrid>
    </Layoutes>
  )
}

export default HomePage;