import React from 'react'

const Tab = ({index, activeTab, children}) => {
  return (
    <div 
      className="single-tab"
      style={{display: index === activeTab ? "block" : "none"}}
    >
      {children}
    </div>
  )
}

export default Tab;