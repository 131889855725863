import React from 'react';

const PrimaryTable = ({headers, children}) => {
    return (
        <div className="table-container">
            <table className="primary-table">
                <thead>
                    <tr>
                    {headers ? headers.map((element, index) => (
                        <th key={index}>{element}</th>
                    )) : ""}
                    </tr>
                </thead>
                <tbody>
                {children}
                </tbody>
            </table>
        </div>
    )
}

export default PrimaryTable;