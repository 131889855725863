import React, { useState } from 'react';
import PrimaryForm from '../../components/Formes/PrimaryForm';
import Layoutes from '../../layouts/Layouts';
import NoImage from '../../assets/icons/no-image-icon.png';
import PrimaryFormRow from '../../components/Formes/PrimaryFormRow';
import PrimaryFormColTwo from '../../components/Formes/PrimaryFormColTwo';
import CategorySeed from '../../seeder/category.seeder';

const AddProduct = () => {
    const [image, setImage] = useState(null);
    const [name, setName] = useState("");
    const [auther, setAuther] = useState("");

    const handleImageChange = (e) => {
        setImage(URL.createObjectURL(e.target.files[0]))
    }

    return (
        <Layoutes title={"Add Product"}>
            <PrimaryForm title={"add new product"}>
                <PrimaryFormRow>
                    <PrimaryFormColTwo>
                    <div className="primary-form-input-file-container">
                        <img src={image ? image : NoImage} alt="Uploaded" className="primary-form-input-file-image" />
                        <input type="file" className="primary-form-input-file" hidden onChange={handleImageChange} id="act-image" />
                        <label htmlFor='act-image' className="primary-form-input-file-upload-button">Upload Image</label>
                    </div>
                    </PrimaryFormColTwo>
                </PrimaryFormRow>
                <PrimaryFormRow>
                    <div className="primary-form-group">
                        <label htmlFor="name">Name</label>
                        <input 
                            type="text"
                            name='name'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="primary-form-input-text"
                        />
                    </div>
                </PrimaryFormRow>
                <PrimaryFormRow>
                    <div className="primary-form-group">
                        <label htmlFor='category'>Category</label>
                        <select className="primary-form-cusome-select">
                            <option>Select category</option>
                            {CategorySeed.map((element, index) => (
                                <option key={index} value={element.name}>{element.name}</option>
                            ))}
                            
                        </select>
                    </div>
                </PrimaryFormRow>
                <PrimaryFormRow>
                    <div className="primary-form-group">
                        <label htmlFor='auther'>Auther</label>
                        <input
                            type="text"
                            name="auther"
                            value={auther}
                            onChange={(e) => setAuther(e.target.value)}
                            className="primary-form-input-text"
                        />
                    </div>
                </PrimaryFormRow>
            </PrimaryForm>
        </Layoutes>
    )
}

export default AddProduct;