import { faker } from '@faker-js/faker';

const CategorySeed = [];

for (var i = 0; i < 10; i++) {
    CategorySeed.push(
        {
            id : i,
            name : faker.commerce.department(),
            url : faker.internet.avatar(),
        }
    )
}

export default CategorySeed;