import React from 'react'
import LoginForm from '../components/Formes/LoginForm'

const LoginPage = () => {
  return (
    <div className="login-page-background">
      <LoginForm title='Welcome back..'/>
    </div>
  )
}

export default LoginPage