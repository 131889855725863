import React from 'react';
import './styles/App.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import CategoriesPage from './pages/categories/CategoriesPage';
import ProductsPage from './pages/Products/ProductsPage';
import CustomersPage from './pages/CustomersPage';
import SettingsPage from './pages/SettingsPage';
import ProfilePage from './pages/ProfilePage';
import AddCategoryPage from './pages/categories/AddCategoryPage';
import AddProduct from './pages/Products/AddProduct';
import { useState } from 'react';
import { AuthContext } from './hooks/contexts/AuthContext';
import PlansPage from './pages/Plans/PlansPage';
import PlaneProfile from './pages/Plans/PlaneProfile';
import FileUploader from './components/FileUploader';
import RedirectToHome from './routes/RedirectToHome';
import NotFoundPage from './pages/NotFoundPage';
import { useEffect } from 'react';
import JwtDecode from 'jwt-decode';
import SubjectsPage from './pages/Subjects/SubjectsPage';
import SeriesPage from './pages/Series/SeriesPage';
import LevelsPage from './pages/Levels/LevelsPage';
import SingleProductPage from './pages/Products/SingleProductPage';

function App() {
  const [user, setUser] = useState("");

  const handleLogout = () => {
    window.localStorage.removeItem("token");
    setUser("");
  }

  useEffect(() => {
    const fetchData = () => {
      const token = localStorage.getItem("token");
      if (token) {
        const data = JwtDecode(token);
        setUser({...data, token : token })
      }
    }
    fetchData();
  }, [])

  if (!user) {
    return (
      <div className="App">
        <LoginPage />
      </div>
    )
  }

  return (
    <AuthContext.Provider value={{user, handleLogout}} >
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<RedirectToHome />} />
          <Route path='home'  element={<HomePage />} />
          {/* <Route path="login" element={<LoginPage />} /> */}
          {/* <Route path='authors' element={<AuthorsPage />} /> */}
          <Route path="categories" element={<CategoriesPage />} />
          <Route path='categories/add' element={<AddCategoryPage />} />
          <Route path="subjects" element={<SubjectsPage />} />
          <Route path='series' element={<SeriesPage />} />
          <Route path='levels' element={<LevelsPage />} />
          <Route path='products' element={<ProductsPage />} />
          <Route path='products/:id' element={<SingleProductPage />} />
          <Route path='products/add' element={<AddProduct />} />
          <Route path='plans' element={<PlansPage />} />
          <Route path='plans/:id' element={<PlaneProfile />} />
          <Route path="customers" element={<CustomersPage />} />
          <Route path="settings" element={<SettingsPage />} />
          <Route path='profile' element={<ProfilePage />} />
          <Route path='fileuploader' element={<FileUploader />} />
          <Route path='/*' element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </div>
    </AuthContext.Provider>
  );
}

export default App;
