import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Message from '../../components/Messages/Message';
import Variables from '../../config/global-variables';
import { AuthContext } from '../../hooks/contexts/AuthContext';

const ProductForm = ({ 
    productId, 
    enId,
    arId,
    lang,
    handleProductChange
}) => {
    const user = useContext(AuthContext);

    const [subjects, setSubjects] = useState([]);
    const [series, setSeries] = useState([]);
    const [levels, setLevels] = useState([]);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [product, setProduct] = useState({
        openSemsem : false,
        series : "",
        level : "",
        subjects : [],
        awareded : false,
        lang : 'en'
    })
    const [formSuccess, setFormSuccess] = useState(null);
    const [formError, setFormError] = useState(null);
    const [formLoading, setFormLoading] = useState(false);

    const handleSubjectChange = (value) => {
        if (product.subjects.includes(value)) {
            setProduct({...product, subjects : product.subjects.filter((element) => element !== value)});
        } else {
            setProduct({...product, subjects : [...product.subjects, value]})
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormLoading(true);
        try {
            const response = await axios.post(`${Variables.domainName}/api/eproducts`, {
                productId : arId,
                openSesame : product.openSemsem,
                awarded : product.awareded || null,
                readingLevel : product.level || null,
                subjects : product.subjects || [],
                bookSeries : product.series || null,
                lang : product.lang,
            })
            if (response && response.data.success) {
                setFormLoading(false);
                setFormError(null);
                setFormSuccess("Created Successfully!");
                setTimeout(() => {
                    handleProductChange({
                        openSesame : product.openSemsem,
                        awarded : product.awareded,
                        readingLevel : product.level,
                        subjects : product.subjects,
                        bookSeries : product.series,
                    })
                }, 2000)
            } else {
                setFormError(response.data.message)
                setFormSuccess(null);
                setFormLoading(false)
            }
        } catch (error) {
            setFormError(error)
            setFormSuccess(null);
            setFormLoading(false)
        }
    }

    const handleReset = () => {
        setProduct({
            subjects : [],
            awareded : false,
            openSemsem : false,
            level : "",
            series : "",
            lang : "en"
        })
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const responseOne = await axios.get(`${Variables.domainName}/api/bookSeries`, {
                    headers : {
                        "Content-Type" : "application/json",
                        "x-auth-token" : user.token,
                    }
                })
                if (responseOne.data.success) {
                    setSeries(responseOne.data.data);
                } else {
                    setError("Failed to loading the data")
                }
                const responseTwo = await axios.get(`${Variables.domainName}/api/readingLevels`, {
                    headers : {
                        "Content-Type" : "application/json",
                        "x-auth-token" : user.token,
                    }
                })
                if (responseTwo.data.success) {
                    setLevels(responseTwo.data.data);
                } else {
                    setError("Failed to loading the data")
                }
                const responseThree = await axios.get(`${Variables.domainName}/api/subjects`, {
                    headers : {
                        "Content-Type" : "application/json",
                        "x-auth-token" : user.token,
                    }
                })
                if (responseThree.data.success) {
                    setSubjects(responseThree.data.data);
                } else {
                    setError("Failed to loading the data")
                }

            } catch (error) {
                console.log(error);
                setError("Failed to loading the data")
            }
            setLoading(false);
        }
        fetchData();
    }, [user.token])

    return (
        <form className="product-form" onSubmit={handleSubmit} >
            {
            formLoading ?
            <div className="form-loading-container">
                <FontAwesomeIcon icon={faSpinner} className="loading-icon" />
            </div> :
            ""
            }
            <h3 className="title">Add Virtual Product</h3>
            {
            formError ?
            <Message type={"error"} text={formError} /> :
            formSuccess ?
            <Message type={"success"} text={formSuccess} /> :
            ""
            }
            <div className="form-row">
                <div className="form-group form-col-2">
                    <label htmlFor='bookLanguage'>Book languge :</label>
                    <select 
                        name='bookLanguage'
                        className="select-input"
                        value={product.lang}
                        onChange={(e) => setProduct({...product, lang : e.target.value})} 
                    >
                        <option value={"en"}>English</option>
                        <option value={"ar"}>Arabic</option>
                    </select>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group form-col-2">
                    <div className="checkbox-group">
                        <input 
                            type="checkbox"
                            id="awareded"
                            name='awareded'
                            className="check-box"
                            value={product.awareded} 
                            checked={product.awareded}
                            onChange={(e) => setProduct({ ...product, awareded : !product.awareded })} 
                        />
                        <label htmlFor='awareded'>Awarded</label>
                    </div>
                </div>
                <div className="form-group form-col-2">
                    <div className="checkbox-group">
                        <input 
                            type="checkbox"
                            id="openSesame"
                            className="check-box"
                            name='openSesame'
                            value={product.openSemsem}
                            checked={product.openSemsem}
                            onChange={(e) =>{
                                setProduct({...product, openSemsem : !product.openSemsem })
                                console.log(e.target.value)
                            }} 
                        />
                        <label htmlFor='openSesame'>Open Semsem</label>
                    </div>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group form-col-2">
                    <label htmlFor='series'>Book Level :</label>
                    <select 
                        name='series'
                        className="select-input"
                        value={product.level}
                        onChange={(e) => setProduct({...product, level : e.target.value})} 
                    >
                    <option value={""}>none</option>
                    {
                    levels.map((element, index) => (
                        <option key={index} value={element._id} >{element.englishName}</option>
                    ))
                    }
                    </select>
                </div>
                <div className="form-group form-col-2">
                    <label htmlFor='series'>Add to series:</label>
                    <select 
                        name='series'
                        className="select-input"
                        value={product.series}
                        onChange={(e) => setProduct({...product, series : e.target.value})} 
                    >
                    <option value={""}>none</option>
                    {
                    series.map((element, index) => (
                        <option key={index} value={element._id} >{element.englishName}</option>
                    ))
                    }
                    </select>
                </div>
            </div>
            
            <div className="form-group">
                <label className="checkbox-group-label">Subjects</label>
                <div className="checkbox-group">
                {
                subjects.map((element, index) => (
                    <div className="checkbox-item" key={index}>
                        <input
                            type="checkbox"
                            className="check-box"
                            id={element._id}
                            name={element._id}
                            value={element._id}
                            checked={product.subjects.includes(element._id) ? true : false}
                            onChange={(e) => {
                                handleSubjectChange(element._id, index)
                            }}
                        />
                        <label htmlFor={element._id}>{element.englishName}</label>
                    </div>
                ))
                }
                </div>
            </div>
            <div className="form-button-group">
                <button 
                    type='button'
                    className="form-secondary-button"
                    onClick={handleReset}
                >
                    Reset
                </button>
                <button 
                    type='submit'
                    className="form-primary-button"
                >
                    Submit
                </button>
            </div>
        </form>
    )
}

export default ProductForm;