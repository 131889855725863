import { faCircleDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const CheckProduct = ({data, validation}) => {
    const attr = data.find((element) => ((element.key === validation)));
    const isAtterExist = attr ? attr.value ? true : false : false;

    return (
        <>
        {
            isAtterExist ?
            <FontAwesomeIcon icon={faCircleDot} size={24} color={"#177da8"} /> :
            null
        }
        </>
    )
}

export default CheckProduct;