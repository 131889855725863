import React from 'react';

const PrimaryForm = ({ title = "Form title", handleSubmit, handleInputChange, children}) => {
    return (
        <div>
            <form className="primary-form" onSubmit={handleSubmit}>
                <div className="form-header">
                    <h3 className="form-title">{title}</h3>
                </div>
                {children}
            </form>
        </div>
    )
}

export default PrimaryForm;