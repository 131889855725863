import React from 'react'
import Layoutes from '../../layouts/Layouts'
import PrimaryTable from '../../components/Tables/PrimaryTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import CategorySeed from '../../seeder/category.seeder';

const headers = [
  "id",
  "name",
  "url",
  "actions"
]

const CategoriesPage = () => {
  return (
    <Layoutes title={"Categories"}>
      <div>
        <Link to="/categories/add" className="button primary-outline-button">
          <FontAwesomeIcon icon={faPlus} />
          <span>Add new Category</span>
        </Link>
        <PrimaryTable headers={headers} >
          {
            CategorySeed.map((element, index) => (
              <tr key={index}>
                <td>{element.id}</td>
                <td>{element.name}</td>
                <td>{element.url}</td>
                <td>
                  <button type="butotn" className="button primary-button">
                    <FontAwesomeIcon icon={faPen} />
                  </button>
                  <button type="button" className="button danger-button">
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </td>
              </tr>
            ))
          }
        </PrimaryTable>
      </div>
    </Layoutes>
  )
}

export default CategoriesPage