import { faExclamationCircle, faExclamationTriangle, faPen, faPlus, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { Formik } from 'formik';
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Message from '../../components/Messages/Message';
import Variables from '../../config/global-variables';
import { AuthContext } from '../../hooks/contexts/AuthContext';
import Layoutes from '../../layouts/Layouts';
import SubjectSchema from '../../schemas/subject.schema';

const LevelsPage = () => {
    const user = useContext(AuthContext);

    const [toggleModal, setToggleModal] = useState(false);

    const [levelId, setLevelId] = useState(null);
    const [levels, setLevels] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(null);

    const [modalMode, setModalMode] = useState("adding")
    const [modalTitle, setModalTitle] = useState("Add new level");

    const [arabicName, setArabicName] = useState("");
    const [englishName, setEnglishName] = useState("");

    const [formSuccess, setFormSuccess] = useState(null);
    const [formError, setFormError] = useState(null);
    const [formLoading, setFormLoading] = useState(false);

    const handleAddLevel = () => {
        setModalMode("adding")
        setModalTitle("Add new level");
        setArabicName("");
        setEnglishName("");
        setToggleModal(true);
    }

    const handleEditLevel = (id, index) => {
        setLevelId(id);
        setArabicName(levels[index].arabicName);
        setEnglishName(levels[index].englishName);
        setModalMode("editing");
        setModalTitle("Edit Level ", index);
        setToggleModal(true);
    }

    const handleCancelModal = () => {
        setToggleModal(false);
    }

    const hadnleDeleteLevel = async (id, index) => {
        const result = window.confirm("Are you sure?");
        if (result) {
            try {
                const response = await axios.delete(`${Variables.domainName}/api/readingLevels/${id}`, {
                    headers : {
                        "Content-Type" : "application/json",
                        "x-auth-token" : user.token,
                    }
                })
                if (response.data.success) {
                    setLevels(levels.filter((element, index) => element._id !== id))
                    alert("Element deleted successfully");
                } else {
                    alert("Something went wrong")
                    console.log("Something went wrong")
                }
            } catch (error) {
                console.log(error);
                alert("Something went wrong");
            }
        }
    }

    const handleSubmitAddition = async (data) => {
        setFormLoading(true);
        if (modalMode === "adding") {
            try {
                const response = await axios.post(`${Variables.domainName}/api/readingLevels`, {
                    arabicName : data.arabicName,
                    englishName : data.englishName
                }, {
                    headers : {
                        "Content-Type" : "application/json",
                        "x-auth-token" : user.token,
                    }
                })
                if (response.data.success) {
                    setFormLoading(false);
                    setFormError(null)
                    setLevels([...levels, { 
                        arabicName : response.data.data.arabicName,
                        englishName : response.data.data.englishName,
                        _id : response.data.data._id,
                    }])
                    setFormSuccess("Level added successfully");
                    setTimeout(() => {
                        setFormSuccess(null);
                        setToggleModal(false);
                    }, 2000)
                } else {
                    setFormSuccess(null);
                    setFormError("Something went wrong")
                    setFormLoading(false);
                }
            } catch (error) {
                setFormSuccess(null);
                setFormError("Something went wrong")
                setFormLoading(false);
            }
        } else {
            try {
                const response = await axios.put(`${Variables.domainName}/api/readingLevels/${levelId}`, {
                    arabicName : data.arabicName,
                    englishName : data.englishName,
                }, {
                    headers : {
                        "Content-Type" : "application/json",
                        "x-auth-token" : user.token,
                    }
                })
                if (response.data.success) {
                    setFormLoading(false);
                    setFormError(null);
                    setLevels(levels.map((element, index) => {
                        if (element._id === levelId) {
                            return {
                                arabicName : response.data.data.arabicName,
                                englishName : response.data.data.englishName,
                                _id : response.data.data.id,
                            }
                        } else {
                            return element;
                        }
                    }))
                    setFormSuccess("Level edited successfullly");
                    setTimeout(() => {
                        setFormSuccess(null);
                        setToggleModal(false);
                    }, 2000)
                } else {
                    setFormSuccess(null);
                    setFormError("Something went wrong!");
                    setFormLoading(false);
                }
            } catch (error) {
                setFormSuccess(null);
                setFormError("Something went wrong!");
                setFormLoading(false);
            }
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${Variables.domainName}/api/readingLevels`, {
                    headers : {
                        "Content-Type" : "application/json",
                        "x-auth-token" : user.token,
                    }
                })
                if (response && response.data.success) {
                    setLevels(response.data.data);
                    setLoadingError(null);
                    setLoading(false);
                } else {
                    setLoadingError("Something went wrong!");
                    setLoading(false);
                }
            } catch (error) {
                setLoadingError("Something went wrong!");
                setLoading(false);
            }
        }
        fetchData();
    }, [user.token])

    return (
        <Layoutes title={"Reading Levels"} >
            <div className={`modal ${toggleModal ? "active" : ""}`}>
                <Formik
                    initialValues={{ englishName : "", arabicName : ""}}
                    validationSchema={SubjectSchema}
                    onSubmit={(values, actions) => {
                        handleSubmitAddition(values)
                        actions.setValues({ arabicName : "", englishName : "" })
                    }}
                >
                {
                props => (
                <form className="modal-form" onSubmit={props.handleSubmit} >
                    {
                    formLoading ?
                    <div className="form-loading-container">
                        <FontAwesomeIcon icon={faSpinner} className="loading-icon" />
                    </div> :
                    ""
                    }
                    <h2 className="title">{modalTitle}</h2>
                    {
                    formError ?
                    <div className="error-wrapper">
                        <Message text={formError} type={"error"} /> 
                    </div> :
                    formSuccess ?
                    <div className="error-wrapper">
                        <Message text={formSuccess} type={"success"} />
                    </div> :
                    ""
                    }
                    <div className="form-group">
                        <label htmlFor="enName">English Name :</label>
                        <input 
                            // id='enName'
                            type="text" 
                            className="form-control"
                            value={props.values.englishName}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            placeholder={englishName}
                            name="englishName"
                        />
                        {props.errors.englishName && <div className="error-text">{props.errors.englishName}</div>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="arName">Arabic Name :</label>
                        <input 
                            // id="arName"
                            type="text"
                            className="form-control"
                            value={props.values.arabicName}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            placeholder={arabicName}
                            name={"arabicName"}
                        />
                        {props.errors.arabicName && <div className="error-text">{props.errors.arabicName}</div>}
                    </div>
                    <div className="form-button-group">
                        <button 
                            type="button" 
                            className="cancel-button"
                            onClick={handleCancelModal}
                        >
                            Cancel
                        </button>
                        <button type="submit" className="submit-button">
                            Submit
                        </button>
                    </div>
                </form>
                )
                }
                </Formik>
            </div>
            <div className="wrapper">
                <button 
                    type="button" 
                    className="button primary-button larg-button"
                    onClick={handleAddLevel}
                >
                    <FontAwesomeIcon icon={faPlus} />
                    <span>Add new Level</span>
                </button>
                {
                loadingError ?
                <div className="loading-container">
                    <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />
                    <h3 className="danger-text">{loadingError}</h3>
                </div> :
                loading ?
                <div className="loading-container">
                    <div className="loading-icon">
                        <FontAwesomeIcon icon={faSpinner} />
                    </div>
                </div> :
                levels.length <= 0 ?
                <div className="loading-container">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="not-found-icon" />
                    <h3 className="primary-text">There are no reading levels</h3>
                </div> :
                <table className="subjects-table">
                    <thead className="header">
                        <tr>
                            <th>English Name</th>
                            <th>Arabic Name</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                    {
                    levels.map((element, index) => (
                        <tr key={index}>
                            <td>{element.englishName}</td>
                            <td>{element.arabicName}</td>
                            <td>
                                <button 
                                    type="button" 
                                    className="button primary-button"
                                    onClick={() => handleEditLevel(element._id, index)}
                                >
                                    <FontAwesomeIcon icon={faPen} />
                                </button>
                                <button 
                                    type="button" 
                                    className="button danger-button"
                                    onClick={() => hadnleDeleteLevel(element._id, index)}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                            </td>
                        </tr>
                    ))
                    }
                    </tbody>
                </table>
                }
            </div>
        </Layoutes>
    )
}

export default LevelsPage;