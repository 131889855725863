import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyDB_OcDGKtatKk4vovYkXosxvJWyb2QuG8",
    authDomain: "fir-test2-9b8a1.firebaseapp.com",
    projectId: "fir-test2-9b8a1",
    storageBucket: "fir-test2-9b8a1.appspot.com",
    messagingSenderId: "860796537968",
    appId: "1:860796537968:web:d41ae59b0efba31cfdf8b5",
    measurementId: "G-11FLSS8JR1"
}

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app, "gs://fir-test2-9b8a1.appspot.com");