import React from 'react'
import { Link } from 'react-router-dom'

const PrimaryInfoCard = ({type = "one", title, icon, subtitle, url, urlText}) => {
  return (
    <div className={`primary-info-card primary-info-card-${type}`}>
        <h5 className="card-title">{title}</h5>
        <div className="card-flex">
            <h3 className="card-subtitle">{subtitle}</h3>
            <div className="card-icon">{icon}</div>
        </div>
        <Link className="card-link" to={url}>{urlText}</Link>
    </div>
  )
}

export default PrimaryInfoCard