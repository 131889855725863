import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Message from '../../components/Messages/Message';
import Variables from '../../config/global-variables';
import { AuthContext } from '../../hooks/contexts/AuthContext';
import Layouts from '../../layouts/Layouts';
import ProductForm from './ProductForm';
import TabsForms from './TabsForms';

const SingleProductPage = () => {
    const user = useContext(AuthContext);
    const { id } = useParams();

    const location = useLocation();
    
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const handleProductChange = (values) => {
        setProduct({
            bookSeries : values.bookSeries,
            awarded : values.awarded,
            readingLevel : values.readingLevel,
            subjects : values.subjects,
            openSesame : values.openSesame
        })
    }

    useEffect(() => {
        console.log(location.state);
        setLoading(true);
        const fetchData = async () => {
            try {
                const response = await axios.get(`${Variables.domainName}/api/eproducts/${location.state.arId}`, {
                    headers : {
                        "Content-Type" : "application/json",
                        "x-auth-token" : user.token,
                    }
                });
                if (response.data.success) {
                    setProduct(response.data.data);
                    setLoading(false);
                    setError(null);
                    console.log(response.data.data);
                } else {
                    setProduct(null)
                    setError("Some thing went wrong try again later")
                    setLoading(false);
                    console.log("Product doesn't exist");
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [id, user.token]);

    return (
        <Layouts>
            <div className="wrapper">
                {
                loading?
                error ?
                <div className="loading-container">
                    <Message type={"error"} text={error} />
                </div> :
                <div className="loading-container">
                    <FontAwesomeIcon icon={faSpinner} className="loading-icon" />
                </div> :
                
                <div className="product-page">
                    {
                    !product ?
                    <ProductForm 
                        productId={id}
                        enId={location.state.enId}
                        arId={location.state.arId}
                        lang={location.state.lang}
                        handleProductChange={handleProductChange}
                    /> :
                    <TabsForms 
                        product={product} 
                        productId={id}
                        enId={location.state.enId}
                        arId={location.state.arId}
                        lang={location.state.lang} 
                        titles={["Product Settings", "Audio", "PDF", "Video"]}
                    />
                    }
                </div>
                }
            </div>
        </Layouts>
    )
}

export default SingleProductPage;