import React from 'react'
import Layoutes from '../layouts/Layouts'

const NotFoundPage = () => {
  return (
    <Layoutes title={""} >
        <div className="not-found-page">
            <h1>404 Not Found</h1>
        </div>
    </Layoutes>
  )
}

export default NotFoundPage