import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const Message = ({ type, text }) => {
    return (
        <div className={`message ${type}-message`}>
            {
            type === "success" ?
            <FontAwesomeIcon icon={faCheckCircle} className="success-icon" /> :
            type === "error" ?
            <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" /> :
            ""
            }
            <span>{text}</span>
        </div>
    )
}

export default Message;