import React from 'react'
import { useState } from 'react';

const AdminForm = ({adminName, adminEmail, adminPhone}) => {
    const [name, setName] = useState(adminName);
    const [email, setEmail] = useState(adminEmail);
    const [phone, setPhone] = useState(adminPhone);

    const handleReset = () => {
        setName(adminName);
        setEmail(adminEmail);
        setPhone(adminPhone);
    }

    return (
        <form className="admin-form">
            <div className="form-group">
                <label htmlFor='name'>Name</label>
                <input 
                    id='name'
                    type="text"
                    name='name'
                    value={name}
                    className="form-input-text"
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <div className="form-group">
                <label htmlFor='email'>Email</label>
                <input 
                    id='email'
                    type="email"
                    name='email'
                    value={email}
                    className="form-input-text"
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div className="form-group">
                <label htmlFor='phone'>Phone</label>
                <input 
                    id='phone'
                    type="tel"
                    name='phone'
                    value={phone}
                    className="form-input-text"
                    onChange={(e) => setPhone(e.target.value)}
                />
            </div>
            <div className="form-button-group">
                <button type="submit" className="admin-form-primary-button">Update</button>
                <button type="reset" className="admin-form-secondary-butotn" onClick={handleReset}>Reset</button>
            </div>
        </form>
    )
}

export default AdminForm;